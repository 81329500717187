//Checks for the fade-in class
(function() {
    if (!'IntersectionObserver' in window) {
        return;
    }
    const elements = document.querySelectorAll('.fade-in');
    let observer = new IntersectionObserver(
        (changes, observer) => {
            let timeout = 0;

            changes.forEach(change => {
                if (change.intersectionRatio > 0) {
                    timeout += 100;
                    setTimeout(() => {
                        change.target.setAttribute('data-state', 'visible');
                    }, timeout);
                }
            });
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        }
    );

    elements.forEach(element => {
        element.setAttribute('data-state', 'hidden');

        observer.observe(element);
    });
})();

//Check for the menu on the home screen
(function() {
    if (!'IntersectionObserver' in window) {
        return;
    }

    let body = document.querySelector('[data-uid="home"]');
    if (!body) {
        return
    }
    let hero = document.querySelector('.hero img');
    let menu = document.querySelector('header');

    let observer = new IntersectionObserver(
        (changes, observer) => {
            let timeout = 0;

            changes.forEach(change => {
                if (change.isIntersecting) {
                    timeout += 100;
                    setTimeout(() => {
                        menu.setAttribute('data-background', 'hidden');
                    }, timeout);
                } else {
                    timeout += 100;
                    setTimeout(() => {
                        menu.setAttribute('data-background', 'visible');
                    }, timeout);
                }
            });
        }, {
            root: null,
            rootMargin: '50px',
            threshold: 0.1
        }
    );

    observer.observe(hero);
})();

// Main menu toggles
const navButtons = document.querySelectorAll('nav li button');
navButtons.forEach(el => {
    el.addEventListener('click', function() {
        let expanded = this.getAttribute('aria-expanded') === 'true';
        this.setAttribute('aria-expanded', !expanded);
        let menu = this.nextElementSibling;
        menu.hidden = !menu.hidden;
    });
});

// Opening and closing the menu
const menuContainer = document.querySelector('#main-menu')
const openMenuButton = document.querySelector('[aria-controls="main-menu"]')
const closeMenuButton = document.querySelector('#close-main-menu')
const firstItem = document.querySelector('.menus-container > button')
const body = document.querySelector('body')
const exitZone = document.querySelector('.exit-zone')

openMenuButton.addEventListener('click', function() {
    let expanded = this.getAttribute('aria-expanded') === 'true';
    this.setAttribute('aria-expanded', !expanded);
    menuContainer.hidden = !menuContainer.hidden;
    firstItem.focus()
});

closeMenuButton.addEventListener('click', function() {
    closeMenu()
});

exitZone.addEventListener('click', function() {
    closeMenu()
});

document.onkeydown = function(evt) {
    evt = evt || window.event;
    if (evt.keyCode == 27 && body.getAttribute('data-overflow')) {
        closeMenu()
    }
};

function closeMenu() {
    body.removeAttribute('data-overflow')
    openMenuButton.setAttribute('aria-expanded', false);
    menuContainer.hidden = !menuContainer.hidden;
    openMenuButton.focus()
}

// Triggers lightbox elements
const images = document.querySelectorAll('[data-lightbox]');
let box = null;
images.forEach(element => {
    element.addEventListener("click", function(event) {
        event.preventDefault();
        box = basicLightbox.create(`<img src="${element.href}">`);
        box.show();
    });
})